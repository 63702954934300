import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthState } from "../store/app.states";
import { Store } from "@ngrx/store";
import { Logout } from "../store/actions/authentication.actions";
import { Constant } from "../constant/constant";
import { NbToastrService } from "@nebular/theme";
@Injectable({
  providedIn: "root",
})
export class LoaderInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private store: Store<AuthState>,
    private constant: Constant,
    private toastrService: NbToastrService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
        },
        (err: any) => {
          this.handleAuthError(err);
        }
      )
    );
  }
  private handleAuthError(err: HttpErrorResponse) {
    if (err.status === this.constant.HTTP_CODES.UNAUTHORIZED) {
      this.showToast(
        this.constant.TOAST_ACTION.FAILURE,
        this.constant.GENERIC_ERROR_MSG.UNAUTHORIZED
      );
    } else if (err.status === this.constant.HTTP_CODES.FORBIDDEN) {
      this.showToast(
        this.constant.TOAST_ACTION.FAILURE,
        this.constant.GENERIC_ERROR_MSG.FORBIDDEN
      );
    }
  }

  showToast(status: any, msg: string) {
    this.toastrService.show("", msg, { status });
  }
}
