import { Component, OnInit, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { getDeepFromObject } from "../../helpers";

import { User } from "./../../models/vta.user";
import { AuthState, selectAuthenticationState } from "./../../store/app.states";
import {
  Login,
  LoginSuccess,
} from "./../../store/actions/authentication.actions";
import { NB_AUTH_OPTIONS, NbAuthSocialLink } from "../../auth.options";
import {
  AnimationSettingsModel,
  SpinSettingsModel,
} from "@syncfusion/ej2-splitbuttons";
import { Constant } from "../../constant/constant";
import { CommonService } from "../../services/common.service";

@Component({
  selector: "nb-login",
  styleUrls: ["./vta.login.component.scss"],
  templateUrl: "./vta.login.component.html",
})
export class LoginComponent implements OnInit {
  user: User = new User();
  getState: Observable<AuthState>;
  errorMessage: string = null;
  submitted: boolean = false;
  showPasswordFlag: boolean = false;
  slideRight: AnimationSettingsModel = { effect: "SlideRight" };
  spinCenter: SpinSettingsModel = { position: "Center" };

  constructor(
    private store: Store<AuthState>,
    private constant: Constant,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    private commonService: CommonService
  ) {
    this.getState = this.store.select(selectAuthenticationState);
  }

  ngOnInit() {
    this.getState.subscribe((state) => {
      this.submitted = false;
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.validateEmail(this.user.email)) {
      const actionPayload = {
        email: this.user.email,
        password: this.user.password,
      };

      this.store.dispatch(new Login(actionPayload));
    }
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  validateEmail = (email: string) => {
    return this.commonService.validateEmail(email);
  };

  togglePassword(id) {
    this.showPasswordFlag = !this.showPasswordFlag;
    let passEleType = (<HTMLInputElement>document.getElementById(id)).type;
    if (passEleType == "password") {
      (<HTMLInputElement>document.getElementById(id)).type = "text";
    } else if (passEleType == "text") {
      (<HTMLInputElement>document.getElementById(id)).type = "password";
    }
  }
}
