import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { HomeComponent } from "./pages/home/vta.home.component";
import {
  LoginComponent,
  NbAuthComponent,
  RegisterComponent,
  NbLogoutComponent,
  RequestPasswordComponent,
  ResetPasswordComponent,
} from "./@theme/components/auth/public_api";
import { VerifyUserComponent } from "./@theme/components/auth/components/verify-user/vta.verify-user.component";
import { ApproveRequestComponent } from "./@theme/components/auth/components/approve-request/vta.approve-request.component";
import { PrivacyPolicyComponent } from "./privacy-policy/vta.privacy-policy.component";
import { TermsAndConditionsComponent } from "./terms-and-conditions/vta.terms-and-conditions.component";

export const routes: Routes = [
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "",
    component: NbAuthComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "register",
        component: RegisterComponent,
      },
      {
        path: "logout",
        component: NbLogoutComponent,
      },
      {
        path: "request-password",
        component: RequestPasswordComponent,
      },
      {
        path: "resetpassword",
        component: ResetPasswordComponent,
      },
      {
        path: "verify",
        component: VerifyUserComponent,
      },
      {
        path: "approve",
        component: ApproveRequestComponent,
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyComponent,
      },
      {
        path: "terms-and-conditions",
        component: TermsAndConditionsComponent,
      },
    ],
  },
  { path: "", redirectTo: "pages", pathMatch: "full" },
  { path: "**", redirectTo: "pages" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
