import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { Constant } from "../constant/constant";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public emailValidationPattern: RegExp;

  constructor(private constant: Constant) {
    this.emailValidationPattern = this.constant.EMAIL_VALIDATION_PATTERN;
  }

  getVacationName(id, timeoffTypesList): string {
    let vacationName = "";
    timeoffTypesList.forEach((element) => {
      if (element.id == id) {
        vacationName = (element || {}).label || "";
      }
    });
    return vacationName;
  }

  getVacationLabelColor(id, timeoffTypesList): string {
    let vacationLabelColor = "";
    timeoffTypesList.forEach((element) => {
      if (element.id == id) {
        vacationLabelColor = (element || {}).label_color || "";
      }
    });
    return vacationLabelColor;
  }

  getDate(date) {
    if (!!date) {
      return DateTime.fromISO(date).toFormat(" dd LLL yyyy");
    }
  }
  getManagerName(id, employeesList): string {
    let managerName = "";
    employeesList.forEach((element) => {
      if (element.id == id) {
        managerName =
          element.name.last +
          ", " +
          element.name.first +
          " " +
          element.name?.middle;
      }
    });
    return managerName;
  }

  getEmployeeName(id, employeesList): string {
    let empName = "";
    employeesList.forEach((element) => {
      if (element.id == id) {
        empName =
          element.name.last +
          ", " +
          element.name.first +
          " " +
          element.name?.middle;
      }
    });
    return empName;
  }

  validateEmail(email: string): boolean {
    return this.emailValidationPattern.test(email);
  }
}
