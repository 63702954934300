<div class="registration">
  <div class="registration__container">
    <div class="registration__left">
      <section class="another-action mr-t-0 logo-section">
        <img
          src="assets/images/easyVTA_logo_for_whiteBG.png"
          alt=""
          height="1%"
          width="100%"
        />
      </section>

      <div *ngIf="!showSuccessMessage">
        <form
          #form="ngForm"
          (ngSubmit)="register(form)"
          aria-labelledby="title"
          class="form"
        >
          <div class="form-group template full-width">
            <div class="e-float-input" [ngClass]="{ 'e-success': name.valid }">
              <input
                type="text"
                id="org-name"
                name="name"
                [(ngModel)]="user.name"
                #name="ngModel"
                required
              />
              <span class="e-float-line"></span>
              <label
                class="e-float-text required"
                [ngClass]="{
                  'e-label-top': user.name !== '',
                  'e-label-bottom': user.name == ''
                }"
                for="name"
                >Organization name</label
              >
            </div>
            <span
              class="e-error"
              *ngIf="
                name.errors &&
                name.errors.required &&
                (name.dirty || name.touched)
              "
            >
              Organization name is required
            </span>
          </div>

          <div class="form-group template two-column">
            <div
              id="mobile-no"
              class="e-float-input"
              [ngClass]="{ 'e-success': email.valid }"
            >
              <input
                type="text"
                id="email"
                name="email"
                [(ngModel)]="user.email"
                #email="ngModel"
                required
              />
              <span class="e-float-line"></span>
              <label
                class="e-float-text required"
                [ngClass]="{
                  'e-label-top': user.email !== '',
                  'e-label-bottom': user.email == ''
                }"
                for="name"
                >Email</label
              >
            </div>
            <ng-container *ngIf="email.touched">
              <p class="caption status-danger" *ngIf="email.errors?.required">
                Email is required!
              </p>

              <p
                class="caption status-danger"
                *ngIf="!isValidEmail(user.email)"
              >
                Email should be the real one
              </p>
            </ng-container>
          </div>

          <div class="form-group template two-column">
            <div
              id="mobile-no"
              class="e-float-input"
              [ngClass]="{ 'e-success': reEmail.valid }"
            >
              <input
                type="text"
                id="reEmail"
                name="reEmail"
                [(ngModel)]="user.reEmail"
                #reEmail="ngModel"
                required
              />
              <span class="e-float-line"></span>
              <label
                class="e-float-text required"
                [ngClass]="{
                  'e-label-top': user.reEmail !== '',
                  'e-label-bottom': user.reEmail == ''
                }"
                for="name"
                >Confirm Email</label
              >
            </div>

            <ng-container *ngIf="reEmail.touched">
              <p
                class="caption status-danger"
                *ngIf="reEmail.invalid && reEmail.errors?.required"
              >
                Email confirmation is required!
              </p>

              <p
                class="caption status-danger"
                *ngIf="
                  email.value != reEmail.value && !reEmail.errors?.required
                "
              >
                Email does not match the confirm email!
              </p>

              <p
                class="caption status-danger"
                *ngIf="!isValidEmail(user.reEmail)"
              >
                Email should be the real one
              </p>
            </ng-container>
          </div>

          <div class="form-group template three-column">
            <div
              class="e-float-input"
              [ngClass]="{ 'e-success': firstName.valid }"
            >
              <input
                type="text"
                id="org-firstName"
                name="firstName"
                [(ngModel)]="user.firstName"
                #firstName="ngModel"
                required
              />
              <span class="e-float-line"></span>
              <label
                class="e-float-text required"
                [ngClass]="{
                  'e-label-top': user.firstName !== '',
                  'e-label-bottom': user.firstName == ''
                }"
                for="firstName"
                >First name</label
              >
            </div>
            <span
              class="e-error"
              *ngIf="
                firstName.errors &&
                firstName.errors.required &&
                (firstName.dirty || firstName.touched)
              "
            >
              First name is required
            </span>
          </div>

          <div class="form-group template three-column">
            <div
              class="e-float-input"
              [ngClass]="{ 'e-success': middleName.valid }"
            >
              <input
                type="text"
                id="org-middleName"
                name="middleName"
                [(ngModel)]="user.middleName"
                #middleName="ngModel"
              />
              <span class="e-float-line"></span>
              <label
                class="e-float-text"
                [ngClass]="{
                  'e-label-top': user.middleName !== '',
                  'e-label-bottom': user.middleName == ''
                }"
                for="middleName"
                >Middle name</label
              >
            </div>
          </div>

          <div class="form-group template three-column">
            <div
              class="e-float-input"
              [ngClass]="{ 'e-success': lastName.valid }"
            >
              <input
                type="text"
                id="org-lastName"
                name="lastName"
                [(ngModel)]="user.lastName"
                #lastName="ngModel"
                required
              />
              <span class="e-float-line"></span>
              <label
                class="e-float-text required"
                [ngClass]="{
                  'e-label-top': user.lastName !== '',
                  'e-label-bottom': user.lastName == ''
                }"
                for="lastName"
                >Last name</label
              >
            </div>
            <span
              class="e-error"
              *ngIf="
                lastName.errors &&
                lastName.errors.required &&
                (lastName.dirty || lastName.touched)
              "
            >
              Last name is required
            </span>
          </div>

          <div class="form-group template center">
            <div class="submitBtn">
              <button
                style="width: 10vw"
                class="submit-btn e-btn"
                id="submit-btn"
                ejs-progressbutton
                content="Join"
                [enableProgress]="true"
                [spinSettings]="spinCenter"
                [duration]="4000"
                [animationSettings]="slideRight"
                cssClass="e-outline e-success"
                type="submit"
                [disabled]="
                  !form.valid ||
                  !isValidEmail(user.email) ||
                  !isValidEmail(user.reEmail)
                "
              >
                Join
              </button>
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="showSuccessMessage" class="success-msg">
        <div class="row" style="justify-content: center">
          <div>
            <div><span>Welcome to easyVTA</span></div>
          </div>
        </div>
        <br />
        <p>
          Your organization has been registered with easyVTA. We have sent you a
          verification email to confirm your email address and set your
          password.
        </p>
        <br />
        <span>easyVTA Team.</span>
        <section class="another-action" aria-label="Sign in">
          <a class="text-link" routerLink="../login">Go back to login page</a>
        </section>
      </div>

      <nb-alert *ngIf="showErrorMessage" outline="danger" role="alert">
        <p class="alert-title">
          <b>{{ registerActionMessage }}</b>
        </p>
      </nb-alert>
    </div>

    <div class="registration__right">
      <section class="info-msg" aria-label="Sign Up">
        <div class="info-msg__step">
          Please enter the following information to start using easyVTA for your
          organization. Once you have registered, you will receive a welcome
          email with access to the next steps.
        </div>

        <div class="info-msg__step">
          If you have been invited to join easyVTA by your organization, please
          refer to your invitation email.
        </div>

        <div class="info-msg__step">
          If you have already accepted an invitation from your organization and
          have set up your account, please log in
          <a class="login-link" routerLink="../login">here.</a>
        </div>
      </section>
    </div>
  </div>
</div>
